@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-size: 16px; /* Default font size for larger screens */
  --font-size-mobile: 14px; /* Font size for mobile screens */
  --font-size-mobile-small: 10px; /* Font size for mobile screens */
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #201a44;
  color: #f2cfff;
  font-family: "Black Ops One", sans-serif;
  font-size: var(--font-size);
}
@media (max-width: 767px) {
  body {
    font-size: var(--font-size-mobile);
  }
}
@media (max-height: 400px) {
  body {
    font-size: var(--font-size-mobile-small);
  }
}

a {
  color: #ce4bff;
}

a:hover {
  color: #a27ac4;
}

.fade-in-section {
  opacity: 0;
  transition: opacity 2s ease-in-out; /* Adjust the transition duration as needed */
}
@media (max-height: 400px) {
  .fade-in-section {
    height: auto;
    min-height: 100vh; /* Adjust this value as needed */
    opacity: 0;
    transition: opacity 2s ease-in-out; /* Adjust the transition duration as needed */
  }
}

.fade-in-section.fade-in {
  opacity: 1;
}

.blog_post h2 {
  font-size: 1.6em;
  font-weight: bold;
  margin: 0.5em 0 0.5em 0;
  color: #a27ac4;
  text-decoration: underline;
}

.blog_post p {
  margin: 0.5em 0 0.5em 0;
  font-size: 1.3em;
  font-family: "Times New Roman", Times, serif;
  color: white;
}

.blog_sub {
  font-size: 1em;
  font-style: italic;
  margin: 0.5em 0 0.5em 0;
  color: #ffffff;
  text-decoration: underline;
}
